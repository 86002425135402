#SectionCompanyGuruAddress{
    .rowTitleAddress{
        padding-top: 60px;
        
        .title{
            color: #390040;
            font-size: 25px;
            font-weight: bold;
            margin-bottom: 40px;
        }

        .colButtonEdit{
            display: flex;
            justify-content: end;
    
            .buttonEdit{
                width: 200px;
                height: 40px;
                font-weight: bold;
                background-color: #390040;
                border-color: #390040;
            }
    
            .buttonCancel{
                width: 200px;
                height: 40px;
                font-weight: bold;
                background-color: #EE880C;
                border-color: #EE880C;
            }
        }
    }

    .cardAddress{
        margin-bottom: 15px;
        border: none;
        border-radius: 30px;
        box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.068);
        padding: 10px 60px 10px 60px;

        .form{ 

            .formTitle{
                font-size: 18px;
                font-weight: bold;
                margin-top: 10px;
                color: #390040;
            }

            .formControl{
                border-color: #EE880C;

                &:focus {
                    box-shadow: 0 0 5px 2px #EE880C;
                    border-color: #EE880C;
                }

                option:checked {
                    background-color: #EE880C;
                    color: #fff;
                }
            }

            .rowButton{
                margin-top: 30px;
                
                .colButtonSend{
                    
                    .button{
                        width: 200px;
                        font-weight: bold;
                        background-color: #390040;
                        border-color: #390040;
                    }
                    
                }
                
                .colButtonCancel{
                    display: flex;
                    justify-content: end;

                    .buttonCancel{
                        width: 200px;
                        font-weight: bold;
                        background-color: #EE880C;
                        border-color: #EE880C;
                        margin-right: 15px;
                    }
                }

            }

            .rowButton{
                margin-top: 30px;
                
                .colButtonSend{
                    
                    .button{
                        width: 200px;
                        font-weight: bold;
                        background-color: #390040;
                        border-color: #390040;
                    }
                    
                }
                
                .colButtonCancel{
                    display: flex;
                    justify-content: end;

                    .buttonCancel{
                        width: 200px;
                        font-weight: bold;
                        background-color: #EE880C;
                        border-color: #EE880C;
                        margin-right: 15px;
                    }
                }

            }
        }
    }
}

@media (max-width:991.98px){
    #SectionCompanyGuruAddress{
        .rowTitleAddress{
            padding-top: 40px;
        }

        .cardAddress{
            padding: 10px 10px 10px 10px;

            .form{ 

                .rowButton{
                    margin-top: 30px;
                    
                    .colButtonSend{
                        
                        .button{
                            width: 200px;
                            font-weight: bold;
                            background-color: #390040;
                            border-color: #390040;
                        }
                        
                    }
                    
                    .colButtonCancel{
                        display: flex;
                        justify-content: end;

                        .buttonCancel{
                            width: 200px;
                            font-weight: bold;
                            background-color: #EE880C;
                            border-color: #EE880C;
                            margin-right: 15px;
                        }
                    }

                }

                .rowButton{
                    margin-top: 30px;
                    
                    .colButtonSend{
                        display: flex;
                        justify-content: end;

                        .button{
                            width: 130px;
                        }
                        
                    }
                    
                    .colButtonCancel{
                        display: block;

                        .buttonCancel{
                            width: 130px;
                        }
                    }

                }
            }
        }
    }
}