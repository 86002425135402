#TopsDaCidadePage{
    .rowTitle{
        padding: 40px 0px 0px 0px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        
        .title{
            width: fit-content;
            color: #390040;
            font-size: 35px;
            font-weight: bold;
        }

        .ano{
            width: fit-content;
            font-size: 35px;
            font-weight: bold;
            color: #EE880C;
        }
    }

    .rowText{
        padding: 0px 0px 20px 0px;
        text-align: center;
        .text{
            font-size: 20px;
            font-weight: bold;
            color: #390040;
        }
    }
}

@media (max-width:991.98px){
    #TopsDaCidadePage{
        .rowTitle{
            padding: 20px 0px 0px 0px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            
            .title{
                width: fit-content;
                color: #390040;
                font-size: 20px;
                font-weight: bold;
            }
    
            .ano{
                width: fit-content;
                font-size: 20px;
                font-weight: bold;
                color: #EE880C;
            }
        }
    
        .rowText{
            padding: 0px 0px 20px 0px;
            text-align: center;
            .text{
                font-size: 16px;
                font-weight: normal;
                color: #390040;
            }
        }
    }
}