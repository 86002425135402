#NotFoundPage{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    
    .container{

        .rowLogo{
            justify-content: center;
            .logo{
                width: 450px;
                height: auto;
            }
        }
        .title{
            font-size: 55px;
            font-weight: bold;
            text-align: center;
            color: #390040;
        }

        .subTitle{
            font-size: 35px;
            font-weight: bold;
            text-align: center;
            color: #390040;
        }

        .text{
            text-align: center;
            color: #390040;
        }

        .rowButton{
            display: flex;
            justify-content: center;
            .button{
                width: auto;
                height: 50px;
                background-color: #390040;
                border-radius: 15px;
                border-color: #390040;
            }
        }
    }
}

@media (max-width:991.98px){
    #NotFoundPage{
        
        .container{
    
            .rowLogo{
                justify-content: center;
                .logo{
                    width: 250px;
                    height: auto;
                }
            }
            .title{
                font-size: 45px;
            }
    
            .subTitle{
                font-size: 25px;
                font-weight: bold;
                text-align: center;
                color: #390040;
            }
    
            .text{
                text-align: center;
                color: #390040;
            }
    
            .rowButton{
                display: flex;
                justify-content: center;
                .button{
                    width: auto;
                    height: 40px;
                    background-color: #390040;
                    border-radius: 15px;
                    border-color: #390040;
                }
            }
        }
    }
}