#PainelPage {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    height: 50vh;
  
    .title {
        text-align: center;
        font-size: 50px;
        font-weight: bold;
        margin-bottom: 1rem;
    }
  
    .description {
        text-align: center;
        font-size: 18px;
        margin-bottom: 2rem;
        color: #343a40;
    }

}
  