#SectionLogin {
    min-height: 800px;
    display: flex;
    justify-content: center;
    align-items: center;
  
    .containerLogin {
        max-width: 800px;
    }
  
    .rowLogo {
        justify-content: center;
  
        .logoLogin {
            max-width: 400px;
            height: auto;
        }
    }
  
    .rowTitle {
        font-size: 25px;
        font-weight: bold;
        text-align: center;
        padding-top: 10px;
    }
  
    .rowForm {
        display: flex;
        flex-direction: column;
        align-items: center;
  
        .formLabel {
            width: 100%;
            border-color: #EE880C;
            border-radius: 15px;
            margin-top: 20px;

            &:focus {
                box-shadow: 0 0 5px 2px #EE880C;
                border-color: #EE880C;
            }
        }

        .rowRecaptcha{
            justify-content: center;
            margin-top: 15px;

            .recaptcha{
                width: auto;
            }
        }
  
        .button {
            width: 100%;
            height: 50px;
            border-radius: 10px;
            border-color: #390040;
            background-color: #390040;
            margin-top: 20px;
        }
    }

    .rowSignUp{
        margin-top: 20px;
        color: #390040;

        .signUpText{
            text-align: center;
        }
    }
}
  