#PartnerRequestsDetailsPage{
    min-height: 60vh;
    
    .rowTitle {
        padding-top: 20px;
        
        .title{
            font-size: 25px;
            font-weight: bold;
            color: #390040;
        }
    }

    .cardClientSchedule{
        margin-bottom: 30px;
        border-radius: 15px;
        box-shadow: 0 0 5px 2px #3900403f;
        color: #390040;
        
        .rowClientSchedule{
            margin: 20px 0px 10px 0px;
            .colPartnerSchedule{
                display: flex;
                justify-content: center;
                box-shadow: 5px 0 5px -5px rgba(0, 0, 0, 0.2);
                .image{
                    width: 140px;
                    height: 140px;
                    object-fit: cover;
                    border-radius: 10%;
                }
            }

            .colServiceSchedule{
                box-shadow: 5px 0 5px -5px rgba(0, 0, 0, 0.2);
                padding: 0px 20px 0px 20px;
            }
            
            .colEmployeeSchedule{
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                text-align: center;            

                .image{
                    width: 80px;
                    height: 80px;
                    object-fit: cover;
                    border-radius: 10%;
                    margin-bottom: 10px;
                }
            }
        }

        .rowPartnerSchedule{
            padding: 20px 10px 10px 10px;
            background-color: #390040;
            border-radius: 15px;
            color: white;
            
            .colPartnerSchedule{
                display: flex;
                justify-content: center;
                box-shadow: 5px 0 5px -5px rgba(238, 136, 12, 0.834);
    
                .image{
                    width: 100px;
                    height: 100px;
                    object-fit: cover;
                    border-radius: 10%;
                }
            }
        }

    }
    
    .rowPayment{
        margin-top: 30px;
        
        .cardPaymentSchedule{
            margin-bottom: 30px;
            border-radius: 15px;
            box-shadow: 0 0 5px 2px #3900403f;

            .rowProgressBar{
                margin: 20px 0px;
            }

            .paymentTitle{
                margin-top: 15px;
                font-size: 25px;
                font-weight: bold;
                text-align: center;
                color: #EE880C;
            }

            .rowPaymentText{
                margin: 20px 0px 0px 0px;
                text-align: center;
                .paymentText{
                    font-size: 18px;
                    font-weight: bold;
                    color: #EE880C;
                    margin: 0;
                }

                .paymentSubText{
                    font-size: 16px;
                    color: #390040;
                    margin: 0;

                }
            }

            .rowCheckoutButton{
                margin: 30px 0px 30px 0px;
        
                .colButtonCancelar{
                    display: flex;
                    justify-content: center;
                    
                    .buttonCancelar{
                        width: 300px;
                        height: 40px;
                        font-weight: bold;
                        background-color: #EE880C;
                        border-color: #EE880C;
                    }
                }
            }

            .rowCancelSchedule{
                margin: 30px 0px 30px 0px;
                display: flex;
                justify-content: center;

                .colCancelSchedule{
                    display: flex;
                    justify-content: end;
                }
        
                .buttonCancelSchedule{
                    width: 300px;
                    height: 40px;
                    font-weight: bold;
                    background-color: #EE880C;
                    border-color: #EE880C;
                }
                
                .buttonFinishedSchedule{
                    width: 300px;
                    height: 40px;
                    font-weight: bold;
                    background-color: #390040;
                    border-color: #390040;
                }

                .paymentSubText{
                    margin-top: 15px;
                    text-align: center;
                    font-size: 18px;
                    font-weight: bold;
                    color: #390040;
                    margin: 0;
                }
            }
        }
        
    }

    .rowButton{

        .button{
            width: 250px;
            height: 40px;
            font-weight: bold;
            background-color: #EE880C;
            border-color: #EE880C;
        }

        .colButtonCancelar{
            display: flex;
            justify-content: end;
            .buttonCancelar{
                width: 150px;
                height: 40px;
                font-weight: bold;
                background-color: #390040;
                border-color: #390040;
            }
        }
    }
}

.modal{
    .title{
        color: #390040;
        font-weight: bold;
    }

    .form{
        .formLabel {
            border-color: #EE880C;
            &:focus {
                box-shadow: 0 0 5px 2px #EE880C;
                border-color: #EE880C;
            }
        }
        
        .formControl{
            color: #EE880C;
        }
    }

    .buttonSave{
        background-color: #390040;
        border-color: #390040;
    }

    .buttonCancel{
        background-color: #EE880C;
        border-color: #EE880C;
    }
}

@media (max-width:991.98px){
    #ClientScheduleDetailsPage{
        min-height: 60vh;
        
        .rowTitle {
            .title{
                font-size: 18px;
                text-align: center;
            }
        }

        .rowPayment{
            .cardPaymentSchedule{
                .rowCheckoutButton{
                    .colButtonCheckout{
                        display: flex;
                        justify-content: center;
                    }
                    
                    .colButtonCancelar{
                        margin-bottom: 10px;
                        justify-content: center;
                    }
                }

                .rowCancelSchedule{
                    .colCancelSchedule{
                        justify-content: center;
                        margin-bottom: 15px;
                    }

                    .colFishedSchedule{
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }

        .rowSchedule{
            padding: 10px;
        }
    
        .cardClientSchedule{
            margin-bottom: 20px;
            
            .rowClientSchedule{
                margin: 20px 0px 10px 0px;
                .colPartnerSchedule{
                    box-shadow: 5px 0 5px -5px rgba(0, 0, 0, 0.2);
                    .image{
                        width: 80px;
                        height: 80px;
                    }
                }
    
                .colServiceSchedule{
                    box-shadow: none;
                    padding: 0px 20px 0px 20px;
                }
                
                .colEmployeeSchedule{
                    margin-top: 10px;
                }
            }
    
            .rowPartnerSchedule{
                padding: 20px 10px 10px 10px;
                
                .colPartnerSchedule{
                    box-shadow: none;
                    margin-bottom: 20px;
        
                    .image{
                        width: 80px;
                        height: 80px;
                        object-fit: cover;
                        border-radius: 10%;
                    }
                }
            }
        }

        .rowButton{

            .colButton{
                display: flex;
                justify-content: center;
                .button{
                    width: 300px;
                    height: 40px;
                    font-weight: bold;
                    background-color: #EE880C;
                    border-color: #EE880C;
                }
            }
    
            .colButtonCancelar{
                display: flex;
                justify-content: center;
                margin-bottom: 10px;

                .buttonCancelar{
                    width: 300px;
                    height: 40px;
                    font-weight: bold;
                    background-color: #390040;
                    border-color: #390040;
                }
            }
        }
    }
}