#Footer{
    background-color: #390040;
    color: #f5f5f5;
    padding: 30px 0px 10px 0px;

    .rowText{
        display: flex;
        flex-direction: column;
        .title{
            font-size: 18px;
            font-weight: bold;
            color: #EE880C;
        }
        .text{
            width: fit-content;
            text-decoration: none;
            margin: 7px 0px 0px 0px;
            font-size: 16px;
            color: #f5f5f5;
        }

        .textClick{
            width: fit-content;
            text-decoration: none;
            margin: 7px 0px 0px 0px;
            font-size: 16px;
            color: #f5f5f5;
            cursor: pointer;
        }
    }

    .rowIcon{
        display: flex;
        justify-content: center;

        .icon{
            width: 55px;
            height: 50px;
        }
    }

    .rowLogo{
        display: flex;
        justify-content: center;
        margin-top: 15px;
        .logo{
            width: 200px;
            height: auto;
        }
    }

    .rowDireitos {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        
        .text{
            width: fit-content;
            .image {
              width: 30px;
              height: auto;
              margin-right: 10px;
            }
        }
    }
}

@media (max-width:991.98px){
    #Footer{
        .rowText{
            display: flex;
            align-items: center;
            text-align: center;
            margin-bottom: 20px;
        }

        .rowDireitos {
            .text{
                text-align: center;

                .image {
                  width: 20px;
                }
            }
        }
    }
}