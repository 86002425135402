#FinancialPage {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 50px 0px 40px 0px;

    .title {
        margin-top: 20px;
        font-size: 25px;
        font-weight: bold;
        color: #390040;
        margin-bottom: 1rem;
    }

    .colButton{
        display: flex;
        justify-content: end;

        .buttonHistorico{
            margin-right: 15px;
            height: 40px;
            font-weight: bold;
            background-color: #EE880C;
            border-color: #EE880C;
        }

        .buttonPost{
            height: 40px;
            font-weight: bold;
            background-color: #EE880C;
            border-color: #EE880C;
        }
    }

    .colWithCalendar {
        position: relative;
        
        .calendarWrapper {
            position: absolute;
            z-index: 1000;
        }
        
        .datePicker {
            width: 97%;
            margin-top: 5px;
            border-radius: 10px;
            border-color: transparent;
            box-shadow: 0 0 5px 2px #00000038;
        }
        
        .buttonCalendarToggle{
            height: 53px;
            background-color: #FFF;
            border-color: #390040;
            color: #390040;
            box-shadow: 0 0 5px 2px #00000038;
            border-radius: 5px;

            .icon{
                color: #390040;
            }
        }

        .buttonDate {
            font-weight: bold;
            background-color: #FFF;
        }
    }

    .rowCard{
        margin: 20px 0px 20px 0px;

        .title{
            font-size: 20px;
            font-weight: bold;
            color: #390040;
            margin-bottom: 30px;
        }

        .card{
            padding: 20px 10px 20px 10px;
            box-shadow: 0 0 5px 2px #00000038;
            border-color: transparent;
            text-align: center;

            .text{
                font-size: 14px;
                color: #EE880C;
                font-weight: bold;
                margin: 0;
            }

            .subText{
                font-size: 18px;
                color: #390040;
                font-weight: bold;
                margin: 0;
            }

            .colIcon{
                display: flex;
                justify-content: center;
                align-items: center;

                .icon{
                    width: 30px;
                    height: 30px;
                    color: #EE880C;
                }
            }
        }
    }

    .rowRepasses{
        margin: 20px 0px 20px 0px;

        .title{
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 30px;
            color: #390040;
        }

        .rowDescription{
            margin: 0;
            padding: 0px 0px 15px 0px;
            border-bottom: 1px solid #3900407e;


            .text{
                font-size: 14px;
                font-weight: bold;
                color: #EE880C;
            }

            .subText{
                font-size: 14px;
                font-weight: bold;
                color: #EE880C;
                text-align: center;
            }
        }

        .listGroup{
            padding: 0;

            .listGroupItem{
                border-color: transparent;
                border-bottom: 1px solid #3900404b;
                
                .rowItem{
                    margin: 0;
                    padding: 15px 0px 15px 0px;
                    color: rgba(0, 0, 0, 0.744);

                    .textTitle{
                        font-size: 14px;
                        font-weight: bold;
                        color: #390040;
                    }

                    .subText{
                        font-size: 14px;
                        font-weight: bold;
                        color: #390040;
                        text-align: center;
                    }

                    .colIcon{
                        display: flex;
                        justify-content: center;
                        align-items: center;
        
                        .icon{
                            width: 20px;
                            height: 20px;
                            color: #EE880C;
                            cursor: pointer;
                        }
                    }

                    .colBadge{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        
                        .badge{
                            height: fit-content;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width:991.98px){
    #FinancialPage {
        margin: 0px 0px 40px 0px;
    
        .colButton{
            justify-content: center;
        }
    
        .colWithCalendar {
            position: relative;
            margin-top: 40px;
        }
    
        .rowCard{   
            .card{
                .subText{
                    margin-bottom: 10px;
                }
            }
        }
    
        .rowRepasses{
            margin: 20px 0px 20px 0px;
    
            .title{
                text-align: center;
            }
    
            .rowDescription{
                .text{
                    text-align: center;
                }
            }
    
            .listGroup{
                .listGroupItem{
                    .rowItem{
                        .textTitle{
                            text-align: center;
                            margin-bottom: 10px;
                        }
    
                        .subText{
                            margin-bottom: 10px;
                        }

                        .colBadge{
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
    }
}