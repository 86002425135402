#BlogPage{

    .divContainer{
        background-color: #390040;

        .container{
            padding: 40px;
    
            .colButton{
                display: flex;
                justify-content: center;
                align-items: center;
    
                .button{
                    border-radius: 50%;
                    background-color: #EE880C;
                    border-color: #EE880C;
                    
                    .icon{
                        margin: 1;
                        width: 20px;
                        height: 30px;
                    }
                }
            }
            .title{
                color: white;
                font-size: 28px;
                text-align: center;
            }
        }
    }
}