#AboutPage{

    .rowCard{
        background-color: #390040;
        
        .colTitle{
            display: flex;
            justify-content: center;
            align-items: center;
            
            .title{
                color:#EE880C;
                font-size: 45px;
                font-weight: bold;
                font-style: bold;
                text-align: center;
            }
        }

        .image{
            width: 100%;
            height: auto;
        }
    }

    .rowInfo{
        .Ititle{
            color: #EE880C;
            font-size: 35px;
            font-weight: bold;
            margin-top: 40px;
              
        }

        .Isubtitle{
            color: #EE880C;
            font-size: 25px;
            font-weight: bold;
            margin-top: 20px;
        }

        .Itext{
            color: #390040;
            font-size: 16px;
        }

        .Isubtext{
            color: #390040;
            font-size: 16px;
        }

        .Atext{
            margin-top: 40px;
            margin-bottom: 40px;
            font-size: 16px;
            color: #390040;
        }
    }
}

@media (max-width:991.98px){
    #HomePage{
        height: auto;
        .container{
            .title{
                font-size: 25px;
            }
    
            .subTitle{
                font-size: 16px;
            }
    
            .subText{
                font-size: 16px;
            }
    
            .text{
                font-size: 16px;
            }
    
            .rowButton{
                margin-top: 20px;
                margin-bottom: 40px;
                
                .button{
                    width: auto;
                    height: 50px;
                    font-weight: bold;
                    background-color: #EE880C;
                    border-color: #EE880C;
                    border-radius: 15px;
                }
            }
        }
    }
}