#ClientListCreatePage{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 40px;

    .title {
        font-size: 25px;
        font-weight: bold;
        margin-bottom: 1rem;
    }
    
    .colButton{
        display: flex;
        justify-content: end;

        .buttonCancel{
            height: 40px;
            width: 200px;
            font-weight: bold;
            background-color: #EE880C;
            border-color: #EE880C;
        }
    }

    .rowClientData{
        margin-top: 30px;
        
        .form{
            .formLabel {
                font-size: 18px;
                font-weight: bold;
                margin-top: 10px;
                color: #390040;
            }
    
            .formControl {
                border-color: #EE880C;
    
                &:focus {
                    box-shadow: 0 0 5px 2px #EE880C;
                    border-color: #EE880C;
                }
            }

            .RowButtonPostBack{
                margin-top: 30px;
                margin-bottom: 30px;
        
                .buttonPost{
                    width: 300px;
                    font-weight: bold;
                    background-color: #EE880C;
                    border-color: #EE880C;
        
                    &:active {
                        box-shadow: 0 0 5px 2px #EE880C;
                        border-color: #EE880C;
                    }
                }
        
                .colButtonBack{
                    display: flex;
                    justify-content: end;
                    .buttonPrev{
                        width: 300px;
                        font-weight: bold;
                        background-color: #390040;
                        border-color: #390040;
            
                        &:active {
                            box-shadow: 0 0 5px 2px #EE880C;
                            border-color: #EE880C;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width:991.98px){
    #ClientListCreatePage{
        margin-top: 20px;
    
        .title {
            text-align: center;
        }
        
        .colButton{
            display: flex;
            justify-content: center;
        }
    
        .rowClientData{
            
            .form{
    
                .RowButtonPostBack{
                    margin-top: 30px;
                    margin-bottom: 30px;

                    .colButtonPost{
                        display: flex;
                        justify-content: center;
                    }
            
                    .buttonPost{
                        width: 300px;
                        font-weight: bold;
                        background-color: #EE880C;
                        border-color: #EE880C;
            
                        &:active {
                            box-shadow: 0 0 5px 2px #EE880C;
                            border-color: #EE880C;
                        }
                    }
            
                    .colButtonBack{
                        justify-content: center;
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
}