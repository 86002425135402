#HistoryFinancialPage {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 50px 0px 40px 0px;

    .title {
        margin-top: 20px;
        font-size: 25px;
        font-weight: bold;
        color: #390040;
        margin-bottom: 1rem;
    }

    .colButton{
        display: flex;
        justify-content: end;

        .buttonHistorico{
            margin-right: 15px;
            height: 40px;
            font-weight: bold;
            background-color: #EE880C;
            border-color: #EE880C;
        }

        .buttonPost{
            height: 40px;
            font-weight: bold;
            background-color: #EE880C;
            border-color: #EE880C;
        }
    }

    .colWithCalendar {
        position: relative;
        
        .calendarWrapper {
            position: absolute;
            z-index: 1000;
        }
        
        .datePicker {
            width: 97%;
            margin-top: 5px;
            border-radius: 10px;
            border-color: transparent;
            box-shadow: 0 0 5px 2px #00000038;
        }
        
        .buttonCalendarToggle{
            height: 53px;
            background-color: #FFF;
            border-color: #390040;
            color: #390040;
            box-shadow: 0 0 5px 2px #00000038;
            border-radius: 5px;

            .icon{
                color: #390040;
            }
        }

        .buttonDate {
            font-weight: bold;
            background-color: #FFF;
        }
    }

    .rowPayment{
        margin-top: 30px;

        .cardClientPayment{
            margin-bottom: 30px;
            border-radius: 15px;
            box-shadow: 0 0 5px 2px #39004056;
            color: #390040;
            
            .rowClientPayment{
                margin: 20px 0px 10px 0px;
                .colPartnerPayment{
                    display: flex;
                    justify-content: center;
                    box-shadow: 5px 0 5px -5px rgba(0, 0, 0, 0.2);
                    .image{
                        width: 140px;
                        height: 140px;
                        object-fit: cover;
                        border-radius: 10%;
                    }
                }
    
                .colServicePayment{
                    box-shadow: 5px 0 5px -5px rgba(0, 0, 0, 0.2);
                    padding: 0px 20px 0px 20px;
                }
                
                .colEmployeePayment{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    text-align: center;            
    
                    .image{
                        width: 80px;
                        height: 80px;
                        object-fit: cover;
                        border-radius: 10%;
                        margin-bottom: 10px;
                    }
    
                    .badge{
                        width: fit-content;
                        height: fit-content;
                    }
                }
            }
    
            .rowPartnerPayment{
                padding: 20px 10px 10px 10px;
                background-color: #390040;
                border-radius: 15px;
                color: white;
                
                .colPartnerPayment{
                    display: flex;
                    justify-content: center;
                    box-shadow: 5px 0 5px -5px rgba(238, 136, 12, 0.834);
        
                    .image{
                        width: 100px;
                        height: 100px;
                        object-fit: cover;
                        border-radius: 10%;
                    }
                }
            }
        }
    }
}

@media (max-width:991.98px){
    #HistoryFinancialPage{
        min-height: 60vh;
        
        .rowTitle {
            text-align: center;
        }

        .rowPayment{
            padding: 10px;
        }
    
        .cardClientPayment{
            margin-bottom: 20px;
            
            .rowClientPayment{
                margin: 20px 0px 10px 0px;
                .colPartnerPayment{
                    box-shadow: 5px 0 5px -5px rgba(0, 0, 0, 0.2);
                    .image{
                        width: 60px;
                        height: 60px;
                    }
                }
    
                .colServicePayment{
                    box-shadow: none;
                    padding: 0px 20px 0px 20px;
                }
                
                .colEmployeePayment{
                    margin-top: 10px;
                }
            }
    
            .rowPartnerPayment{
                padding: 20px 10px 10px 10px;
                
                .colPartnerPayment{
                    box-shadow: none;
                    margin-bottom: 20px;
        
                    .image{
                        width: 80px;
                        height: 80px;
                        object-fit: cover;
                        border-radius: 10%;
                    }
                }
            }
        }
    }
}