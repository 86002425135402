#ProfilePage{
    min-height: 60vh;
    
    .rowTitle {
        font-size: 25px;
        font-weight: bold;
        padding-top: 20px;

        .colButton{
            display: flex;
            justify-content: end;

            .button {
                width: 200px;
                height: 40px;
                border-radius: 10px;
                border-color: #390040;
                background-color: #390040;
                font-weight: bold;
            }
            
            .buttonPassword{
                width: 200px;
                height: 40px;
                margin-right: 15px;
                border-radius: 10px;
                border-color: #390040;
                background-color: #390040;
                font-weight: bold;
            }
        }

    }

    .rowForm {

        .formLabel {
            width: 100%;
            border-color: #EE880C;
            border-radius: 15px;
            margin-top: 20px;

            &:focus {
                box-shadow: 0 0 5px 2px #EE880C;
                border-color: #EE880C;
            }

            &:disabled {
                background-color: #ee880c04;
            }
        }

        .formCheckbox{
            margin-top: 15px;
            color: #390040;

            &:checked{
                background-color: #390040;
            }
        }

        .rowRecaptcha{
            justify-content: center;
            margin-top: 15px;

            .recaptcha{
                width: auto;
            }
        }

        .rowTitleAddress{
            color: #390040;
            font-size: 25px;
            font-weight: bold;
            padding-top: 60px;

            .colButtonAdress{
                display: flex;
                justify-content: end;
                
                .button {
                    width: 40%;
                    height: 50px;
                    border-radius: 10px;
                    border-color: #390040;
                    background-color: #390040;
                    margin-bottom: 20px;
                    font-weight: bold;
                }
            }
            
        }

        .cardAdress{
            margin-bottom: 15px;
            border: none;
            border-radius: 30px;
            box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.068);
            padding: 10px 60px 10px 60px;
        }

        .rowButton{

            .colCancel{
                display: flex;
                justify-content: end;
                .buttonCancel {
                    width: 50%;
                    height: 50px;
                    border-radius: 10px;
                    border-color: #EE880C;
                    background-color: #EE880C;
                    margin-top: 20px;
                    font-weight: bold;
                }
            }

            .button {
                width: 50%;
                height: 50px;
                border-radius: 10px;
                border-color: #390040;
                background-color: #390040;
                margin-top: 20px;
                font-weight: bold;
            }
            
        }

        .rowVerMais{
            display: flex;
            justify-content: center;

            .buttonVerMais{
                width: 200px;
                height: 50px;
                border-radius: 10px;
                border-color: #390040;
                background-color: #390040;
                margin-top: 20px;
                margin-bottom: 20px;
                font-weight: bold;
            }
        }
  
    }
}

@media (max-width:991.98px){
    #ProfilePage{
        .rowTitle {
            text-align: center;
            font-size: 25px;
            font-weight: bold;
            padding-top: 20px;
    
            .colButton{
                display: flex;
                justify-content: center;
            }
        }
    }
}