#ServicesCreatePage{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 40px;

    .title {
        font-size: 25px;
        font-weight: bold;
        margin-bottom: 1rem;
    }

    .rowForm{

        .form{

            .formTitle{
                font-size: 18px;
                font-weight: bold;
                margin-top: 10px;
                color: #390040;
            }

            .colPhoto{
                display: flex;
                justify-content: center;
                
                .photo{
                    margin-top: 15px;
                    width: 200px;
                    height: 150px;
                }
            }

            .formControl{
                border-color: #EE880C;

                &:focus {
                    box-shadow: 0 0 5px 2px #EE880C;
                    border-color: #EE880C;
                }

                option:checked {
                    background-color: #EE880C;
                    color: #fff;
                }
            }

            .dropdownsForm {
                border-radius: 20px;
                height: 45px;
                background-color: #EE880C;
                border-color: #EE880C;
                font-weight: bold;
            }
            
            .dropdownsFormMenu{
                width: 100%;
            }

            .selectedDrop {
                margin-top: 10px;
                display: flex;
                flex-wrap: wrap;
                gap: 5px;

                .selectedDrop {
                    background-color: #390040;
                    border-color: #390040;
                    color: #fff;
                    padding: 5px 10px;
                    border-radius: 20px;

                    &:hover {
                        background-color: #EE880C;
                        border-color: #EE880C;
                    }
                }
            }

            .rowToggle{
                .textSimulator{
                    font-size: 18px;
                    font-weight: bold;
                    margin-top: 10px;
                    color: #390040;
                    margin-bottom: 10px;
                }
                
                .buttonToggleGroup {
                    display: flex;
                    flex-direction: column;
            
                    .buttonToggle {
                        background-color: transparent;
                        color: black;
                        border-radius: 10px 10px 0px 0px;
                        border-color: #EE880C;
            
                        &.active, &[aria-pressed="true"] {
                            background-color: #EE880C;
                            border-color: #EE880C;
                            color: #fff;
                        }
                        
                        &:hover {
                            background-color: #390040;
                            border-color: #390040;
                            color: #fff;
                        }
                    }
    
                    .buttonToggleDown {
                        background-color: transparent;
                        color: black;
                        border-radius: 0px 0px 10px 10px;
                        border-color: #EE880C;
            
                        &.active, &[aria-pressed="true"] {
                            background-color: #EE880C;
                            border-color: #EE880C;
                            color: #fff;
                        }
                        
                        &:hover {
                            background-color: #390040;
                            border-color: #390040;
                            color: #fff;
                        }
                    }
                }
            }

            .rowCard{
                margin: 0;
                .cardValues{
                    padding: 10px;
                    box-shadow: 0 0 5px 2px #ee880c7e;
                    border-color: transparent;
    
                    .valuesTitle{
                        font-size: 20px;
                        font-weight: bold;
                    }
    
                    .values{
                        font-size: 20px;
                        font-weight: bold;
                        text-align: end;
                    }
    
                    .taxaValues{
                        text-align: end;
                    }
                }
            }

            .rowButton{
                display: flex;
                justify-content: center;
                margin-top: 40px;

                .button{
                    width: 200px;
                    height: 40px;
                    font-weight: bold;
                    background-color: #390040;
                    border-color: #390040;
                }

                .colButtonCancelar{
                    display: flex;
                    justify-content: end;
                    
                    .buttonCancelar{
                        width: 200px;
                        height: 40px;
                        font-weight: bold;
                        background-color: #EE880C;
                        border-color: #EE880C;
                        margin-right: 15px;
                    }
                }

            }

        }
    }
}

@media (max-width:991.98px){
    #ServicesCreatePage{
        .rowForm{
            .form{
                .dropdownsForm {
                    width: 100%;
                }
                
                .rowButton{

                    .colButtonSend{
                        display: flex;
                        justify-content: center;
                    }

                    .colButtonCancelar{
                        justify-content: center;
                        
                        .buttonCancelar{
                            margin-right: 0px;
                            margin-bottom: 15px;
                        }
                    }
                }
            }
        }
    }
}

