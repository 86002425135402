#PoliticasCookiesPage{
    .rowCard{
        margin-bottom: 20px;
        padding: 35px 0px 35px 0px;
        background-color: #390040;

        .Ptext{
            color: #FFFFFF;
            font-weight: bold;
            text-align: center;
            font-size: 60px;
        }
    }    

    .rowpdf{
        margin-bottom: 40px;

        .iframePdf{
            width: 100%;
            height: 640px;
            border: none;
        }
    }
}

@media (max-width:991.98px){
    #PoliticasCookiesPage{
        .rowCard{
            margin-bottom: 20px;
            padding: 25px 0px 15px 0px;
            background-color: #390040;
    
            .Ptext{
                font-size: 30px;
            }
        }    

        .rowpdf{
            margin-bottom: 20px;

            .iframePdf{
                width: 100%;
                height: 450px;
                border: none;
            }
        }
    }
}