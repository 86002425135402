#FinancialCreatePage {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 40px;

    .title {
        font-size: 25px;
        font-weight: bold;
        margin-bottom: 1rem;
    }

    .rowForm{
        width: 100%;
        margin-left: 0px;

        .colButtonSelect{

            .buttonSelect{
                width: fit-content;
                background-color: #390040;
                border-color: #390040;
            }
        }

        .select {
            font-size: 20px;
            border-color: #EE880C;
            margin-bottom: 10px;
            
            &:focus {
                box-shadow: 0 0 5px 2px #EE880C;
                border-color: #EE880C;
            }

            option:checked {
                background-color: #EE880C;
                color: #fff;
            }

        }

        .titleForm{
            font-size: 18px;
            font-weight: bold;
            margin-top: 10px;
            color: #390040;
        }

        .formControl {
            border-color: #EE880C;

            &:focus {
                box-shadow: 0 0 5px 2px #EE880C;
                border-color: #EE880C;
            }

            option:checked {
                background-color: #EE880C;
                color: #fff;
            }
        }

        .formControlMask{
            width: 100%;
            height: 53px;
            border: 1px solid #EE880C;
            border-radius: 5px;
            box-shadow: none;
            outline: none;
            padding-left: 12px;
            
            &:focus {
                box-shadow: 0 0 5px 2px #EE880C;
                border-color: #EE880C;
            }
        }

        .rowToggle{
            .textSimulator{
                font-size: 18px;
                font-weight: bold;
                margin-top: 10px;
                color: #390040;
                margin-bottom: 10px;
            }
            
            .buttonToggleGroup {
                display: flex;
                flex-direction: column;
        
                .buttonToggle {
                    background-color: transparent;
                    color: black;
                    border-radius: 10px 10px 0px 0px;
                    border-color: #EE880C;
        
                    &.active, &[aria-pressed="true"] {
                        background-color: #EE880C;
                        border-color: #EE880C;
                        color: #fff;
                    }
                    
                    &:hover {
                        background-color: #390040;
                        border-color: #390040;
                        color: #fff;
                    }
                }

                .buttonToggleDown {
                    background-color: transparent;
                    color: black;
                    border-radius: 0px 0px 10px 10px;
                    border-color: #EE880C;
        
                    &.active, &[aria-pressed="true"] {
                        background-color: #EE880C;
                        border-color: #EE880C;
                        color: #fff;
                    }
                    
                    &:hover {
                        background-color: #390040;
                        border-color: #390040;
                        color: #fff;
                    }
                }
            }
        }

        .rowCard{
            margin: 0;
            .cardValues{
                padding: 10px;
                box-shadow: 0 0 5px 2px #ee880c7e;
                border-color: transparent;

                .valuesTitle{
                    font-size: 20px;
                    font-weight: bold;
                }

                .values{
                    font-size: 20px;
                    font-weight: bold;
                    text-align: end;
                }

                .taxaValues{
                    text-align: end;
                }
            }
        }

        
    }

    .rowClientData{
        margin-top: 30px;

        .title{
            font-size: 18px;
            font-weight: bold;
            margin-top: 10px;
            color: #390040;
        }

        .inputControl{
            border-color: #EE880C;
    
            &:focus {
                box-shadow: 0 0 5px 2px #EE880C;
                border-color: #EE880C;
            }
        }

        .icon{
            color: #EE880C;
        }

        .formLabel {
            font-size: 16px;
            font-weight: bold;
            margin-top: 10px;
            color: #390040;
        }

        .formControl {
            border-color: #EE880C;

            &:focus {
                box-shadow: 0 0 5px 2px #EE880C;
                border-color: #EE880C;
            }

            option:checked {
                background-color: #EE880C;
                color: #fff;
            }
        }

        .formControlMask{
            width: 100%;
            height: 53px;
            border: 1px solid #EE880C;
            border-radius: 5px;
            box-shadow: none;
            outline: none;
            padding-left: 12px;
            
            &:focus {
                box-shadow: 0 0 5px 2px #EE880C;
                border-color: #EE880C;
            }
        }
    }

    .RowButtonNextBack{
        margin-top: 30px;
        margin-bottom: 30px;

        .buttonNext{
            width: 300px;
            font-weight: bold;
            background-color: #EE880C;
            border-color: #EE880C;

            &:active {
                box-shadow: 0 0 5px 2px #EE880C;
                border-color: #EE880C;
            }
        }

        .colButtonClient{
            display: flex;
            justify-content: center;

            .buttonClient{
                width: 300px;
                font-weight: bold;
                background-color: #390040;
                border-color: #390040;
    
                &:active {
                    box-shadow: 0 0 5px 2px #EE880C;
                    border-color: #EE880C;
                }
            }
        }

        .colButtonBack{
            display: flex;
            justify-content: end;
            .buttonPrev{
                width: 300px;
                font-weight: bold;
                background-color: #390040;
                border-color: #390040;
    
                &:active {
                    box-shadow: 0 0 5px 2px #EE880C;
                    border-color: #EE880C;
                }
            }
        }
    }
}

@media (max-width:991.98px){
    #FinancialCreatePage{
        .RowButtonNextBack{

            .colButtonNext{
                display: flex;
                justify-content: center;
                
            }
            
            .colButtonBack{
                display: flex;
                justify-content: center;
                margin-bottom: 15px;
            }
        }
    }
}