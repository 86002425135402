#NewsLetterBlogPage{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
  
    .title {
        font-size: 25px;
        font-weight: bold;
        margin-bottom: 1rem;
    }

    .listGroup{
        margin: 10px 10px 40px 10px;

        .text{
            font-size: 16px;
            margin: 10px 10px 0px 10px;
        }

        .colBadge{
            display: flex;
            justify-content: center;
            align-items: center;
            
            .badge{
                width: 60px;
                height: fit-content;
            }
        }
    }
}