#EmployeesEditPage {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 40px;

    .title {
        font-size: 25px;
        font-weight: bold;
        margin-bottom: 1rem;
    }

    .colButton{
        display: flex;
        justify-content: end;

        .buttonDelete{
            width: 200px;
            height: 40px;
            font-weight: bold;
            background-color: #390040;
            border-color: #390040;
        }
    }

    .rowForm {
        .form {
            .formTitle {
                font-size: 18px;
                font-weight: bold;
                margin-top: 10px;
                color: #390040;
            }

            .formSubTitle {
                font-size: 16px;
                margin-left: 12px;
                margin-top: 5px;
                color: #390040;
            }

            .colPhoto {
                display: flex;
                justify-content: center;
                
                .photo {
                    margin-top: 15px;
                    width: 200px;
                    height: 150px;
                }
            }

            .formControl {
                border-color: #EE880C;

                &:focus {
                    box-shadow: 0 0 5px 2px #EE880C;
                    border-color: #EE880C;
                }

                option:checked {
                    background-color: #EE880C;
                    color: #fff;
                }
            }

            .colForm {
                padding: 30px;
            }
        
            .rowCard {
                width: auto;
                min-height: 360px;
                border-radius: 19px;
                border-width: 1px;
                border-style: solid; 
                border-color: #390040;
            }
            
            .noBorder {
                border: none;
            }
            
            .colDay {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                background-color: #390040;
                border-radius: 15px;
                color: white;
                
                .dayLabel {
                    font-size: 25px;
                    font-weight: bold;
                    text-align: center;
                    margin-top: 10px;
                    color: white;
                }
            }
        
            .rowButton{
                display: flex;
                justify-content: center;
                margin-top: 40px;

                .button{
                    width: 200px;
                    height: 40px;
                    font-weight: bold;
                    background-color: #390040;
                    border-color: #390040;
                }

                .buttonCancelar{
                    width: 200px;
                    height: 40px;
                    font-weight: bold;
                    background-color: #EE880C;
                    border-color: #EE880C;
                    margin-right: 15px;
                }
            }

            .dropdownsForm {
                border-radius: 20px;
                height: 45px;
                background-color: #EE880C;
                border-color: #EE880C;
                font-weight: bold;
            }

            .selectedDrop {
                margin-top: 10px;
                display: flex;
                flex-wrap: wrap;
                gap: 5px;

                .selectedDrop {
                    background-color: #390040;
                    border-color: #390040;
                    color: #fff;
                    padding: 5px 10px;
                    border-radius: 20px;

                    &:hover {
                        background-color: #EE880C;
                        border-color: #EE880C;
                    }
                }
            }
        }
    }
}

.modal{
    .buttonDelete{
        background-color: #390040;
        border-color: #390040;
    }

    .buttonCancel{
        background-color: #EE880C;
        border-color: #EE880C;
    }
}

@media (max-width: 991.98px) {
    #EmployeesEditPage {
        .title {
            text-align: center;
        }
        .colButton{
            justify-content: center;
        }
        .rowForm {
            .form {
                .formTitle {
                    font-size: 16px;
                    font-weight: bold;
                    margin-top: 10px;
                    color: #390040;
                }
    
                .formSubTitle {
                    font-size: 16px;
                    margin-left: 12px;
                    margin-top: 5px;
                    color: #390040;
                }

                .rowButton{
                    .button{
                        width: 150px;
                    }
    
                    .buttonCancelar{
                        width: 150px;
                    }
                }
            }
        }
    }
}
