.buttonSidebar{
    width: 100%;
    background-color: #390040;
    border-color: #390040;

    &.active {
        background-color: #EE880C;
        border-color: #EE880C;
    }
    &:hover {
        background-color: #EE880C;
        border-color: #EE880C;
    }

    .iconMenu{
        width: 35px;
        height: 35px;
    }
}

.trialPeriod{
    background-color: #EE880C;
    border-color: #EE880C;
    
    .trialPeriodContainer{
        padding: 10px;
        
        .text{
            text-align: center;
            color: white;
            font-size: 20px;
            margin: 0;
        }
    }
}

#SideMenu {
    background-color: #390040;
    color: white;
    padding-top: 10px;
    padding-bottom: 10px;

    .offHeader{
        width: 100%;
    }

    .offbodyTitle{
        margin-left: 10px;
    }

    .colButtonClose{
        display: flex;
        justify-content: end;

        .btnClose{
            color: #EE880C;
            background-color: transparent;
            border-color: transparent;

            .btnCloseIcon{
                width: 22px;
                height: 22px;
            }
        }
    }


    .offbody{
        padding: 0px;
    }

    .nav{
        .title{
            color: #EE880C;
            padding-right: 10px;
        }

        .dropPartner{
            border-radius: 20px;
            height: 45px;
            background-color: #EE880C;
            border-color: #EE880C;
            font-weight: bold;
        }
        
        .dropPartnerItem{
            color: #390040;
            font-weight: bold;
            
            &:hover {
                color: white;
                background-color: #EE880C;
            }
        }
        
        .navItem {
            padding: 0px 10px 0px 10px;

            .navLink {
                display: flex;
                align-items: center;
                padding: 10px;
                color: white;
                text-decoration: none;

                &.active {
                    border-radius: 20px;
                    background-color: #EE880C;
                }
                &:hover {
                    border-radius: 20px;
                    background-color: #EE880C;
                }
            }
        }
      
        .icon {
            font-size: 20px;
            margin-right: 10px;
        }
        .text {
            font-size: 16px;
            font-weight: bold;
        }
    }
  
}


@media (max-width:991.98px){
    #SideMenu {
        width: 90%;
        background-color: #390040;
        color: white;
        padding-top: 10px;
        padding-bottom: 10px;
    
        .offbody{
            padding: 0px;
        }
    
        .nav{
            display: flex;
            flex-direction: column;
            margin-bottom: 20px;

            .dropPartner{
                width: 90%;
                margin-bottom: 20px;
                margin-left: 10px;
            }
            
            .dropPartnerMenu{
                width: 90%;
            }

            .navItem {
                .navLink {
                    padding: 15px 20px;
                }
            }
          
            .icon {
                font-size: 25px;
                margin-right: 20px;
            }
            .text {
                font-size: 14px;
                font-weight: bold;
            }
        }
      
    }

    .trialPeriod{
        .trialPeriodContainer{
            padding: 5px;
            
            .text{
                font-size: 16px;
            }
        }
    }
}