#Pagination{
    margin-top: 20px;
    margin-bottom: 40px;
    .container{
        display: flex;
        justify-content: center;
        
        .rowButton{
            width: auto;
            display: flex;
            justify-content: space-between;
            gap: 5px;

            .button{
                background-color: #390040;
                border-color: #390040;
            }

            .buttonPress{
                background-color: #210025;
                border-color: #210025;
            }
        }
    }
}