#PostBlogPage{
    .divContainer{
        background-color: #390040;

        .container{
            padding: 40px;
    
            .colButton{
                display: flex;
                justify-content: center;
                align-items: center;
    
                .button{
                    border-radius: 50%;
                    background-color: #EE880C;
                    border-color: #EE880C;
                    
                    .icon{
                        width: 20px;
                        height: 30px;
                    }
                }
            }
            .title{
                color: white;
                font-size: 28px;
                font-weight: bold;
                text-align: center;
                margin-bottom: 0px;
            }
        }
    }

    .divBanner{
        .banner{
            width: 100%;
            height: 640px;
        }
    }

    .divCategories{
        .rowDate{
            margin-top: 30px;
            .date{
                font-size: 16px;
                font-weight: bold;
                width: fit-content;
                background-color: #EE880C;
                color: white;
                border-radius: 50px;
                padding: 5px 20px 5px 20px;
                margin-left: 30px;
            }
        }
        
        .rowCategory{
            width: 100%;
            margin-left: 6px;

            .divCategory{
                max-width: max-content;

                .category{
                    font-size: 16px;
                    font-weight: bold;
                    width: fit-content;
                    background-color: #EE880C;
                    color: white;
                    border-radius: 50px;
                    padding: 5px 20px 5px 20px;
                }
            }
        }
    }

    .divBodyPost{
        margin: 30px 0px 30px 0px;
    }

    .divNewsletter{
        margin-bottom: 50px;
        .container{
            .card{
                background-color: #390040;
                border-radius: 30px;
                padding: 30px 30px 30px 30px;

                .title{
                    margin-bottom: 2px;
                    font-size: 25px;
                    font-weight: bold;
                    color: white;
                }

                .subTitle{
                    margin-bottom: 3px;
                    font-size: 18px;
                    color: white;
                }

                .formTitle{
                    margin-top: 10px;
                    font-size: 18px;
                    font-weight: bold;
                    color: #EE880C;
                }

                .formControl{
                    border-color: #EE880C;
    
                    &:focus {
                        box-shadow: 0 0 5px 2px #EE880C;
                        border-color: #EE880C;
                    }
    
                    option:checked {
                        background-color: #EE880C;
                        color: #fff;
                    }
                }

                .button{
                    width: 100px;
                    font-weight: bold;
                    background-color: #EE880C;
                    border-color: #EE880C;
                }

                .checkbox{
                    margin-top: 10px;
                    margin-bottom: 10px;
                    font-size: 13px;
                    font-weight: bold;
                    color: #EE880C;
                }
            }
        }
    }
}

@media (max-width:991.98px){
    #PostBlogPage{
        .divContainer{
    
            .container{

                .title{
                    font-size: 22px;
                }
            }
        }
    
        .divBanner{
            .banner{
                height: 250px;
            }
        }

        .divNewsletter{
            .container{
                .card{
                    .title{
                        margin-bottom: 2px;
                        font-size: 20px;
                        font-weight: bold;
                        color: white;
                    }
    
                    .subTitle{
                        margin-bottom: 3px;
                        font-size: 14px;
                        color: white;
                    }
    
                    .formTitle{
                        margin-top: 10px;
                        font-size: 14px;
                        font-weight: bold;
                        color: #EE880C;
                    }
    
                    .formControl{
                        border-color: #EE880C;
        
                        &:focus {
                            box-shadow: 0 0 5px 2px #EE880C;
                            border-color: #EE880C;
                        }
        
                        option:checked {
                            background-color: #EE880C;
                            color: #fff;
                        }
                    }
    
                    .button{
                        margin-top: 10px;
                    }
    
                    .checkbox{
                        font-size: 12px;
                    }
                }
            }
        }
    }
}