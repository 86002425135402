#SectionSchedulesGuru {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 40px;

    .title {
        font-size: 25px;
        font-weight: bold;
        margin-bottom: 1rem;
    }

    .colButtonLink {
        display: flex;
        justify-content: end;

        .buttonNewLink {
            height: 40px;
            font-weight: bold;
            background-color: #EE880C;
            border-color: #EE880C;
        }
    }
    
    .rowButton {
        margin-top: 15px;
        margin-bottom: 40px;

        .colWithCalendar {
            position: relative;

            .calendarWrapper {
                position: absolute;
                z-index: 1000;
            }
        }

        .datePicker {
            margin-top: 5px;
            border-radius: 10px;
        }

        .colButton {
            display: flex;
            justify-content: end;

            .buttonNew {
                height: 40px;
                font-weight: bold;
                background-color: #EE880C;
                border-color: #EE880C;
            }
        }

        .icon {
            margin-right: 10px;
        }
        
        .buttonDate {
            height: 40px;
            font-weight: bold;
            background-color: #390040;
            border-color: #390040;
            margin-right: 20px;
        }
    }

    .rowResumeSchedules {
        margin-top: 15px;
        margin-bottom: 40px;
        border-radius: 19px;
        border-width: 1px;
        border-style: solid; 
        border-color: #390040;
        box-shadow: 0 0 5px 2px #3900407a;

        .colSchedules {
            padding: 10px 20px 5px 20px;

            .titleSchedules {
                font-size: 22px;
                font-weight: bold;
                color: #390040;
            }
            
            .textSchedules {
                font-size: 16px;
                color: #390040;
            }
        }

        .colCounter {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #390040;
            border-radius: 15px;
            padding: 20px 20px 5px 20px;

            .counter {
                font-size: 40px;
                font-weight: bold;
                color: white;
            }
        }
    }

    .rowDropdown {
        
        .inputIcon {
            display: flex;
            justify-content: center;
            width: 150px;
            height: 80px;
            background-color: #918B8B;
            border-color: #918B8B;
            color: white;

            .icon {
                width: 50px;
                height: 50px;
            }
        }
        
        .select {
            font-size: 30px;
            background-color: #918B8B;
            border-color: #918B8B;
            color: white;
            
            &:focus {
                box-shadow: 0 0 5px 2px #EE880C;
                border-color: #EE880C;
            }

            option:checked {
                background-color: #EE880C;
                color: #fff;
            }

            .selectText {
                font-size: 22px;
                text-align: center;
            }
        }
    }

    .rowTable{
        background-color: #390040;
        border-color: #390040;
        border-radius: 10px;
        margin-bottom: 20px;
        
        .colTable{
            color: white;
            padding: 10px 10px 0px 10px;

            .text{
                text-align: center;
                font-weight: bold;
            }
        }
    }

    .rowTableSchedule{
        margin-bottom: 15px;

        .colTableSchedule {
            border-radius: 15px;
            padding: 10px 10px 0px 10px;

            .text {
                text-align: center;
                font-weight: bold;
                color: white;
            }
        }

        .colTableScheduleOccupied {
            background-color: #EE880C;
        }
        
        .colTableScheduleAvailable {
            background-color: #390040;
        }
        
        .colTableScheduleText {
            padding: 10px 10px 0px 10px;

            .text {
                text-align: center;
                font-weight: bold;
                color: #390040;
            }
        }

        .colButtonWhats{
            display: flex;
            justify-content: center;
            align-items: center;

            .buttonWhats{
                background-color: #E1DFDF;
                border-color: #390040;
                color: #15AA23;
                font-weight: bold;

                .icon{
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
    
    .rowTableScheduleAvailable{
        border-radius: 19px;
        border-width: 1px;
        border-style: solid; 
        border-color: #390040;
        box-shadow: 0 0 5px 2px #39004046;
    }

    .rowTableScheduleOccupied{
        border-radius: 19px;
        border-width: 1px;
        border-style: solid; 
        border-color: #EE880C;
        box-shadow: 0 0 5px 2px #EE880C46;
    }

    .rowInvoicing{
        margin-top: 40px;
        border-radius: 19px;
        border-width: 1px;
        border-style: solid; 
        border-color: #390040;
        box-shadow: 0 0 5px 2px #39004046;

        .colInvoicing{
            background-color: #390040;
            border-radius: 15px;
            
            .text{
                padding: 10px 10px 0px 10px;
                color: white;
                font-weight: bold;
            }
        }
        
        .colValue{
            
            .value{
                padding: 10px 10px 0px 10px;
                text-align: center;
                color: #390040;
                font-weight: bold;
            }
        }
    }
}

.modal{

    .modalHeader{
        
        .title{
            color: #390040;
            font-weight: bold;
        }
    }

    .modalBody{

        .rowCliente{
            margin-top: 30px;

            .title{
                font-size: 22px;
                font-weight: bold;
                color: #390040;
            }
            .text{
                font-size: 18px;
                color: #390040;
            }
        }
        .text{
            font-size: 18px;
            color: #390040;
        }
    }

    .modalFooter{
        display: flex;
        justify-content: center;

        .buttonExit{
            width: 200px;
            font-weight: bold;
            background-color: #EE880C;
            border-color: #EE880C;
        }
    }

}

@media (max-width:991.98px){
    #SectionSchedulesGuru {
        margin-top: 20px;
    
        .title {
            text-align: center;
        }

        .colButtonLink {
            display: flex;
            justify-content: center;
    
            .buttonNewLink {
                width: 300px;
            }
        }
        
        .rowButton {
            margin-top: 0px;
            margin-bottom: 40px;
    
            .colWithCalendar {
                position: relative;
                display: flex;
                justify-content: center;
                margin-top: 10px;
    
                .calendarWrapper {
                    margin-top: 40px;
                }

                .buttonDate {
                    margin-left: 20px;
                    width: 300px;
                }
            }
    
            .colButton {
                justify-content: center;
                margin-top: 10px;
                .buttonNew {
                    width: 300px;
                }
            }
    
        }
    
        .rowResumeSchedules {
            margin: 0px;
        }
    
        .rowDropdown {
            margin-top: 20px;
            .inputIcon {
                width: 50px;
                height: auto;
                .icon {
                    width: 40px;
                    height: 40px;
                }
            }
            
            .select {
                font-size: 20px;
            
                .selectText {
                    font-size: 20px;
                }
            }
        }
    
        .rowTable{
            display: none;
        }
    
        .rowTableSchedule{
            margin: 0px 0px 15px 0px;
    
            .colTableSchedule {
                border-radius: 15px;
                padding: 10px 10px 0px 10px;
    
                .text {
                    text-align: center;
                    font-weight: bold;
                    color: white;
                }
            }
    
            .colTableScheduleOccupied {
                background-color: #EE880C;
            }
            
            .colTableScheduleAvailable {
                background-color: #390040;
            }
            
            .colTableScheduleText {
                padding: 10px 10px 0px 10px;
    
                .text {
                    text-align: center;
                    font-weight: bold;
                    color: #390040;
                }
            }
    
            .colButtonWhats{
                display: flex;
                justify-content: center;
                align-items: center;
    
                .buttonWhats{
                    background-color: #E1DFDF;
                    border-color: #390040;
                    color: #15AA23;
                    font-weight: bold;
    
                    .icon{
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }
    
        .rowInvoicing{
            margin-top: 40px;
            border-radius: 19px;
            border-width: 1px;
            border-style: solid; 
            border-color: #390040;
            box-shadow: 0 0 5px 2px #39004046;
    
            .colInvoicing{
                background-color: #390040;
                border-radius: 15px;
                
                .text{
                    padding: 10px 10px 0px 10px;
                    color: white;
                    font-weight: bold;
                }
            }
            
            .colValue{
                
                .value{
                    padding: 10px 10px 0px 10px;
                    text-align: center;
                    color: #390040;
                    font-weight: bold;
                }
            }
        }
    }
}