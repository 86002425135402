#SectionBannerBlog{
    margin-top: 50px;
    .carousel{
        display: flex;
        justify-content: center;
        align-items: center;
        transition: transform 0.6s ease, opacity 0.6s ease;
        
        .image{
            border-radius: 15px;
            width: 100%;
            height: 320px;
            object-fit: cover;
        }
    }
}

@media (max-width:991.98px){
    #SectionBannerBlog{
        .carousel{
            .image{
                border-radius: 15px;
                width: 100%;
                height: 200px;
                object-fit: cover;
            }
        }
    }
}