#SectionBlog{
    padding: 60px 100px 60px 100px;

    .colCard{
        display: flex;
        justify-content: center;
        padding: 30px 20px 30px 20px;
        
        .card{
            background-color: #390040;
            border-radius: 30px;
            width: 100%;
            min-height: 480px;
            max-height: fit-content;
            box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.437);
            
            .links{
                text-decoration: none;

                .image{
                    border-radius: 28px;
                    width: 100%;
                    height: 230px;
                    object-fit: cover;
                    margin-bottom: 10px;
                }

                .date{
                    font-size: 12px;
                    font-weight: bold;
                    width: fit-content;
                    background-color: #EE880C;
                    color: white;
                    border-radius: 50px;
                    padding: 5px 20px 5px 20px;
                    margin-left: 10px;
                }

                .rowCategory{
                    width: 100%;
                    margin-left: 0px;

                    .divCategory{
                        max-width: max-content;
    
                        .category{
                            font-size: 12px;
                            font-weight: bold;
                            width: fit-content;
                            background-color: #EE880C;
                            color: white;
                            border-radius: 50px;
                            padding: 5px 20px 5px 20px;
                        }
                    }
                }

                
                .title{
                    height: 60px;
                    padding: 0px 15px 0px 15px;
                    font-size: 18px;
                    font-weight: bold;
                    color: white;
                }
                
                .resume{
                    padding: 5px 15px 5px 15px;
                    font-size: 16px;
                    color: white;
                }
            }
        }
    }
}

@media (max-width:991.98px){
    #SectionBlog{
        padding: 60px 10px 60px 10px;
    
        .colCard{
            display: flex;
            justify-content: center;
            padding: 30px 20px 30px 20px;
            
            .card{
                .links{
                    .image{
                        height: 190px;
                    }
                }
            }
        }
    }
}