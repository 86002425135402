#Menu{
    
    .navBar {
        background-color: #f5f5f5;
    }
  
    .logo {
        max-width: 300px;
        height: auto;
    }
    
    .menuItens{
        margin-left: auto !important;
    }

    .menuText{
        font-size: 22px;
        font-weight: bold;
        padding-top: 15px;
        padding-right: 30px;
        color: #390040;
        text-decoration: none;
    }

    .menuDropdownText{
        font-size: 16px;
        font-weight: bold;
        color: #390040;

        .text{
            color: white;
        }

        .subText{
            font-size: 20px;
            font-weight: bold;
            color: #390040;
        }
    }

    .icon{
        width: 50px;
        height: 50px;
        color: #390040;
    }
  
}

@media (max-width: 991.98px) {
    #Menu {
        .menuItens {
            display: flex;
            flex-direction: column; 
            width: 100%;
            align-items: center;
        }

        .buttonNav{
            border-color: transparent;
            color: transparent;
        }
  
        .menuText {
            padding: 10px 0;
            text-align: center;
            font-size: 16px;
        }
  
        .menuDropdownText {
            text-align: center;
            margin-top: 10px;
            border-radius: 20px;
        }
      
        .logo {
            max-width: 120px;
        }
    }
}
  