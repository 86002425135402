#SectionClientListGuru{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 40px;

    .title {
        font-size: 25px;
        font-weight: bold;
        margin-bottom: 1rem;
    }
    
    .colButton{
        display: flex;
        justify-content: end;

        .buttonPost{
            height: 40px;
            font-weight: bold;
            background-color: #EE880C;
            border-color: #EE880C;
        }
    }

    .rowDescription{
        font-size: 18px;
        font-weight: bold;
        color: #390040;
        text-align: center;
        margin-top: 15px;
        padding-left: 5px;
        padding-right: 20px;
    }

    .listGroup{
        margin-top: 15px;

        .colImage{
            display: flex;
            justify-content: center;

            .image{
                width: 100%;
                height: auto;
            }
        }

        .colText{
            display: flex;
            align-items: center;
            padding: 10px 10px 0px 10px;

            .text{
                text-align: center;
            }
        }

        .colSubText{
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px 10px 0px 10px;

            .subText{
                text-align: center;
            }
        }

        .colIcon{
            display: flex;
            justify-content: center;
            align-items: center;

            .iconEdit{
                color: #390040;
                width: 20px;
                height: 20px;
                margin-right: 25px;
            }

            .iconDelete{
                color: #390040;
                width: 20px;
                height: 20px;
            }
        }
    }
}

.modal{
    .buttonDelete{
        background-color: #390040;
        border-color: #390040;
    }

    .buttonCancel{
        background-color: #EE880C;
        border-color: #EE880C;
    }
}

@media (max-width:991.98px){
    #SectionClientListGuru{
        margin-top: 20px;
    
        .title {
            text-align: center;
        }
        
        .colButton{
            justify-content: center;
    
            .buttonPost{
                width: 300px;
                height: 40px;
                font-weight: bold;
                background-color: #390040;
                border-color: #390040;
            }
        }
    
        .rowDescription{
            display: none;
        }
    
        .listGroup{
            margin-top: 20px;
    
            .colImage{
                display: flex;
                justify-content: center;
    
                .image{
                    width: 80px;
                    height: auto;
                }
            }
        }
    }
}