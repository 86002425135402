#BlogPostsPage{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 40px;

    .title {
        font-size: 25px;
        font-weight: bold;
        margin-bottom: 1rem;
    }

    .rowDescription{
        display: flex;
        justify-content: center;
        padding-right: 10px;
        margin-top: 10px;
        
        .textTitle{
            font-weight: bold;
        }

        .subText{
            text-align: center;
            font-weight: bold;
        }
    }

    .colButton{
        display: flex;
        justify-content: end;

        .buttonPost{
            height: 40px;
            font-weight: bold;
            background-color: #390040;
            border-color: #390040;
        }
    }


    .listGroup{
        margin: 20px 0px 40px 0px;

        .text{
            font-size: 16px;
            margin: 10px 10px 0px 10px;
        }

        .date{
            font-size: 16px;
            text-align: center;
            margin: 10px 10px 0px 10px;
        }

        .colBadge{
            display: flex;
            justify-content: center;
            align-items: center;
            
            .badge{
                width: auto;
                height: fit-content;
            }
        }

        .rowIcon{
            padding: 10px 10px 10px 10px;
            display: flex;
            justify-content: space-around;

            .iconEdit{
                color: #390040;
                width: 20px;
                height: 20px;
            }

            .iconDelete{
                color: #390040;
                width: 20px;
                height: 20px;
            }
        }
    }

}

.modal{
    .buttonDelete{
        background-color: #390040;
        border-color: #390040;
    }

    .buttonCancel{
        background-color: #EE880C;
        border-color: #EE880C;
    }
}

@media (max-width:991.98px){
    #BlogPostsPage {

        .rowDescription{
            display: none;
        }

        .listGroup{
            padding-right: 5%;

            .text{
                font-size: 18px;
                text-align: center;
            }

            .colBadge{
                margin-top: 10px;
            }
        }
    }
}