#SchedulesCreatePage {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 40px;

    .title {
        font-size: 25px;
        font-weight: bold;
        margin-bottom: 1rem;
    }

    .rowForm{
        width: 100%;
        margin-left: 0px;
        .colDropDownTitle{

            .title{
                font-size: 18px;
                font-weight: bold;
                margin-top: 10px;
                color: #390040;
            }
        }

        .colButtonAdd{
            display: flex;
            justify-content: end;
            padding-top: 5px;
    
            .buttonAdd{
                width: 100px;
                height: fit-content;
                background-color: #390040;
                border-color: #390040;
            }
        }

        .select {
            font-size: 20px;
            border-color: #EE880C;
            margin-bottom: 10px;
            
            &:focus {
                box-shadow: 0 0 5px 2px #EE880C;
                border-color: #EE880C;
            }

            option:checked {
                background-color: #EE880C;
                color: #fff;
            }

        }

        .colFormTitle{
            margin-top: 15px;
            background-color: #390040;
            border-radius: 15px;
            text-align: center;
            height: fit-content;

            .title{
                font-size: 18px;
                font-weight: bold;
                margin-top: 10px;
                color: white;
            }

        }
        
        .colFormControl{
            margin-top: 15px;
            
            .formControl {
                height: 53px;
                border-color: #EE880C;
    
                &:focus {
                    box-shadow: 0 0 5px 2px #EE880C;
                    border-color: #EE880C;
                }
    
                option:checked {
                    background-color: #EE880C;
                    color: #fff;
                }
            }

            .formControlMask{
                width: 100%;
                height: 53px;
                border: 1px solid #EE880C;
                border-radius: 5px;
                box-shadow: none;
                outline: none;
                padding-left: 12px;
                
                &:focus {
                    box-shadow: 0 0 5px 2px #EE880C;
                    border-color: #EE880C;
                }
            }
        }
    }

    .RowButtonNextBack{
        margin-top: 30px;
        margin-bottom: 30px;

        .buttonNext{
            width: 300px;
            height: 40px;
            font-weight: bold;
            background-color: #EE880C;
            border-color: #EE880C;

            &:active {
                box-shadow: 0 0 5px 2px #EE880C;
                border-color: #EE880C;
            }
        }

        .colButtonBack{
            display: flex;
            justify-content: end;
            .buttonPrev{
                width: 300px;
                height: 40px;
                font-weight: bold;
                background-color: #390040;
                border-color: #390040;
    
                &:active {
                    box-shadow: 0 0 5px 2px #EE880C;
                    border-color: #EE880C;
                }
            }
        }
    }

    .RowFindSchedule{
        margin-top: 20px;
        margin-bottom: 50px;

        .findText{
            font-size: 25px;
            font-weight: bold;
            text-align: center;
        }

        .colButtonColab{
            display: flex;
            justify-content: end;
            margin-top: 10px;

            .buttonColab{
                width: 300px;
                font-weight: bold;
                background-color: #390040;
                border-color: #390040;

                &:active {
                    box-shadow: 0 0 5px 2px #EE880C;
                    border-color: #EE880C;
                }
            }
        }

        .colButtonDate{
            margin-top: 10px;

            .buttonDate{
                width: 300px;
                font-weight: bold;
                background-color: #390040;
                border-color: #390040;

                &:active {
                    box-shadow: 0 0 5px 2px #EE880C;
                    border-color: #EE880C;
                }
            }
        }
    }

    .RowScheduleHour{
        margin-top: 15px;
        margin-bottom: 40px;

        .colHourTitle{
            background-color: #390040;
            border-radius: 5px;
            text-align: center;
            height: fit-content;

            .title{
                font-size: 18px;
                font-weight: bold;
                margin-top: 10px;
                color: white;
            }

        }
        
        .colWithCalendar {
            position: relative;
            
            .calendarWrapper {
                position: absolute;
                z-index: 1000;
            }
        }
        
        .datePicker {
            margin-top: 5px;
            border-radius: 10px;
        }
        
        .icon {
            margin-right: 10px;
        }

        .buttonCalendarToggle{
            height: 53px;
            background-color: #FFF;
            border-color: #390040;
            color: #390040;
            box-shadow: 0 0 5px 2px #39004079;
            border-radius: 5px;
        }
        
        .buttonDate {
            font-weight: bold;
            background-color: #FFF;
        }

        .inputButtonToday{
            display: flex;
            align-items: center;

            .buttonToday{
                font-weight: bold;
                color: #390040;
            }
        }
    }

    .RowPaginationDate{
        .title{
            font-size: 20px;
            font-weight: bold;
        }
    }

    .RowPaginationHour{
        .title{
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 35px;
        }

        .colTimeOptions{
            margin-bottom: 10px;
            .buttonTime{
                width: 100%;
                color: #390040;
                font-weight: bold;
                background-color: #D9D9D9;
                border: #D9D9D9;
            }
        }
    }

    .RowPartnerGuru{
        .partnerTitle{
            padding: 25px;
            font-size: 18px;
            text-align: center;
            color: white;
            background-color: #390040;
            border-radius: 15px;
        }

        .partnerCard{
            background-color: #D9D9D9;
            border-color: #D9D9D9;
            box-shadow: 0 0 5px 2px #00000025;
            padding-top: 15px;

            &.cardSelected {
                background-color: #EE880C;
                border-color: #EE880C;
            }


            .divPartnerImage{
                display: flex;
                justify-content: center;
                
                .partnerImage{
                    border-radius: 50%;
                    width: 100px;
                    height: 100px;
                }
            }

            .partnerName{
                font-size: 18px;
                font-weight: bold;
                text-align: center;
                color: #390040;

                &.cardSelected {
                    color:  white;
                }
            }
        }
    }

    .rowSchedulingData{
        padding: 0px 0px 0px 30px;
        .titleSchedulingData{
            font-size: 18px;
            font-weight: bold;
            color: #390040;
        }
    }

    .rowSchedulingResume{
        background-color: #D9D9D9;
        border-radius: 15px;
        padding: 35px 30px 20px 30px;

        .titleResume{
            font-size: 18px;
            color: #390040;
        }
    }

    .rowSchedulingResumeClient{
        margin-top: 15px;
        background-color: #D9D9D9;
        border-radius: 15px;
        padding: 35px 30px 20px 30px;

        .titleResume{
            font-size: 18px;
            color: #390040;
        }
    }
    
    .rowClientTitle{
        margin-top: 30px;
        background-color: #390040;
        border-radius: 15px;
        padding: 15px 0px 0px 30px;

        .titleClientTitle{
            font-size: 18px;
            font-weight: bold;
            color: white;
        }
    }

    .rowSearchClient{
        margin-top: 30px;

        .title{
            font-size: 18px;
            font-weight: bold;
            margin-top: 10px;
            color: #390040;
        }

        .inputControl{
            border-color: #EE880C;
    
            &:focus {
                box-shadow: 0 0 5px 2px #EE880C;
                border-color: #EE880C;
            }
        }

        .icon{
            color: #EE880C;
        }
    }

    .rowClientData{
        margin-top: 30px;
        
        .form{
            .formLabel {
                font-size: 18px;
                font-weight: bold;
                margin-top: 10px;
                color: #390040;
            }
    
            .formControl {
                border-color: #EE880C;
    
                &:focus {
                    box-shadow: 0 0 5px 2px #EE880C;
                    border-color: #EE880C;
                }
    
                option:checked {
                    background-color: #EE880C;
                    color: #fff;
                }
            }
        }
    }
}

@media (max-width:991.98px){
    #SchedulesCreatePage {
        margin-top: 20px;
    
        .title {
            text-align: center;
        }
    
        .rowForm{
            margin-left: 0px;
        }
    
        .RowButtonNextBack{

            .colButtonNext{
                display: flex;
                justify-content: center;
                
            }
            .colButtonBack{
                display: flex;
                justify-content: center;
                margin-bottom: 15px;
            }
        }

        .RowFindSchedule{
            .colButtonColab{
                display: flex;
                justify-content: center;
                margin-top: 10px;
            }
    
            .colButtonDate{
                display: flex;
                justify-content: center;
                margin-top: 10px;
            }
        }

        .RowScheduleHour{
            margin-top: 20px;
            margin-bottom: 20px;
            
            .colHourTitle{
                margin-bottom: 10px;
            }
        }        
    
        .RowPartnerGuru{
            .RowEmployeeGuru{
                margin: 0px;
            }
            .colPartnerGuru{
                padding: 10px;
            }
        }
    
        .rowSchedulingData{
            padding: 0px 0px 0px 30px;
        }
        
        .rowSchedulingResume{
            margin: 0px 0px 0px 0px;
            padding: 20px 20px 20px 20px;
        }
        
        .rowSchedulingResumeClient{
            margin: 15px 0px 0px 0px;
            padding: 20px 20px 20px 20px;
    
            .titleResume{
                font-size: 18px;
                color: #390040;
            }
        }
        
        .rowClientTitle{
            margin-top: 30px;
            background-color: #390040;
            border-radius: 15px;
            padding: 15px 0px 0px 30px;
    
            .titleClientTitle{
                font-size: 18px;
                font-weight: bold;
                color: white;
            }
        }
    
        .rowSearchClient{
            margin-top: 30px;
    
            .title{
                font-size: 18px;
                font-weight: bold;
                margin-top: 10px;
                color: #390040;
            }
    
            .inputControl{
                border-color: #EE880C;
        
                &:focus {
                    box-shadow: 0 0 5px 2px #EE880C;
                    border-color: #EE880C;
                }
            }
    
            .icon{
                color: #EE880C;
            }
        }
    
        .rowClientData{
            margin-top: 30px;
            
            .form{
                .formLabel {
                    font-size: 18px;
                    font-weight: bold;
                    margin-top: 10px;
                    color: #390040;
                }
        
                .formControl {
                    border-color: #EE880C;
        
                    &:focus {
                        box-shadow: 0 0 5px 2px #EE880C;
                        border-color: #EE880C;
                    }
        
                    option:checked {
                        background-color: #EE880C;
                        color: #fff;
                    }
                }
            }
        }
    }    
}