#SectionCompanyGuru{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 40px;

    .title {
        font-size: 25px;
        font-weight: bold;
        margin-bottom: 1rem;
    }

    
    .colButtonEdit{
        display: flex;
        justify-content: end;

        .buttonEdit{
            width: 200px;
            height: 40px;
            font-weight: bold;
            background-color: #390040;
            border-color: #390040;
        }

        .buttonCancel{
            width: 200px;
            height: 40px;
            font-weight: bold;
            background-color: #EE880C;
            border-color: #EE880C;
        }
    }


    .rowForm{

        .form{

            .formTitle{
                font-size: 18px;
                font-weight: bold;
                margin-top: 10px;
                color: #390040;
            }

            .rowBanner{
                
                .colBanner{
                    display: flex;
                    justify-content: center;

                    .banner{
                        margin-top: 15px;
                        width: 200px;
                        height: 150px;
                    }
                }
            }

            .formControl{
                border-color: #EE880C;

                &:focus {
                    box-shadow: 0 0 5px 2px #EE880C;
                    border-color: #EE880C;
                }

                option:checked {
                    background-color: #EE880C;
                    color: #fff;
                }
            }

            .dropdownsForm {
                border-radius: 20px;
                height: 45px;
                background-color: #EE880C;
                border-color: #EE880C;
                font-weight: bold;
            }

            .dropdownsFormMenu{
                width: 100%;
            }

            .selectedDrop {
                margin-top: 10px;
                display: flex;
                flex-wrap: wrap;
                gap: 5px;

                .selectedDrop {
                    background-color: #390040;
                    border-color: #390040;
                    color: #fff;
                    padding: 5px 10px;
                    border-radius: 20px;

                    &:hover {
                        background-color: #EE880C;
                        border-color: #EE880C;
                    }
                }
            }

            .rowButton{
                margin-top: 30px;
                
                .colButtonSend{
                    
                    .button{
                        width: 200px;
                        font-weight: bold;
                        background-color: #390040;
                        border-color: #390040;
                    }
                    
                }
                
                .colButtonCancel{
                    display: flex;
                    justify-content: end;

                    .buttonCancel{
                        width: 200px;
                        font-weight: bold;
                        background-color: #EE880C;
                        border-color: #EE880C;
                        margin-right: 15px;
                    }
                }

            }
        }
    }
}

@media (max-width:991.98px){
    #SectionCompanyGuru{
        margin-top: 30px;
    
        .title {
            text-align: center;
        }

        .colButtonEdit{
            justify-content: center;
        }
    
        .rowForm{
            .form{
    
                .dropdownsForm {
                    width: 100%;
                }
    
                .rowButton{
                    margin-top: 30px;
                    padding-left: 15px;
                    padding-right: 15px;
                    
                    .colButtonSend{
                        display: flex;
                        justify-content: end;

                        .button{
                            width: 150px;
                        }
                        
                    }
                    
                    .colButtonCancel{
                        display: block;

                        .buttonCancel{
                            width: 150px;
                        }
                    }

                }
            }
        }
    }
}