#FinancialPayoutPage {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 50px 0px 40px 0px;

    .title {
        margin-top: 20px;
        font-size: 25px;
        font-weight: bold;
        color: #390040;
        margin-bottom: 1rem;
    }

    .colButton{
        display: flex;
        justify-content: end;

        .buttonPost{
            height: 40px;
            font-weight: bold;
            background-color: #EE880C;
            border-color: #EE880C;
        }
    }

    .colWithCalendar {
        position: relative;
        
        .calendarWrapper {
            position: absolute;
            z-index: 1000;
        }
        
        .datePicker {
            width: 97%;
            margin-top: 5px;
            border-radius: 10px;
            border-color: transparent;
            box-shadow: 0 0 5px 2px #00000038;
        }
        
        .buttonCalendarToggle{
            height: 53px;
            background-color: #FFF;
            border-color: #390040;
            color: #390040;
            box-shadow: 0 0 5px 2px #00000038;
            border-radius: 5px;

            .icon{
                color: #390040;
            }
        }

        .buttonDate {
            font-weight: bold;
            background-color: #FFF;
        }
    }

    .rowCard{
        margin: 20px 0px 20px 0px;

        .title{
            font-size: 20px;
            font-weight: bold;
            color: #390040;
            margin-bottom: 30px;
        }

        .card{
            padding: 20px 20px 20px 20px;
            box-shadow: 0 0 5px 2px #00000038;
            border-color: transparent;

            .rowItem{

                .colCard{
                    gap: 0;
                }
    
                .text{
                    text-align: center;
                    font-size: 14px;
                    color: #EE880C;
                    font-weight: bold;
                    margin: 0;
                }
    
                .subText{
                    text-align: center;
                    font-size: 18px;
                    color: #390040;
                    font-weight: bold;
                    margin: 0;
                }
    
                .colSubText{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
    
                .textValue{
                    font-size: 18px;
                    color: #EE880C;
                    font-weight: bold;
                    margin: 0;
                }
    
                .subTextValue{
                    font-size: 16px;
                    color: #390040;
                    font-weight: bold;
                    margin: 0;
                }
    
                .textFinalValue{
                    text-align: center;
                    font-size: 14px;
                    color: #EE880C;
                    font-weight: bold;
                    margin: 0;
                }
    
                .colIcon{
                    display: flex;
                    justify-content: center;
                    align-items: center;
    
                    .icon{
                        width: 30px;
                        height: 30px;
                        color: #EE880C;
                    }
                }
            }

            .rowSubItem{
                margin-top: 20px;
                .info{
                    color: rgba(0, 0, 0, 0.663);
                    font-size: 14px;
                    font-weight: bold;
                    margin: 0;
                }
    
                .infoValue{
                    font-size: 14px;
                    text-align: center;
                    color: #390040;
                    font-weight: bold;
                    margin: 0;
                }

                .colIcon{
                    display: flex;
                    justify-content: center;
                    align-items: center;
    
                    .icon{
                        width: 20px;
                        height: 20px;
                        color: #EE880C;
                    }
                }
            }
        }
    }
}

@media (max-width:991.98px){
    #FinancialPayoutPage {
        margin: 0px 0px 40px 0px;
    
        .title {
            text-align: center;
        }
    
        .rowCard{
            margin: 20px 0px 20px 0px;
    
            .title{
                font-size: 20px;
                font-weight: bold;
                color: #390040;
                margin-bottom: 30px;
            }
    
            .card{
                margin: 0px 0px 20px 0px;
    
                .rowItem{
    
                    .colCard{
                        gap: 0;
                    }
        
                    .text{
                        text-align: center;
                        font-size: 14px;
                        color: #EE880C;
                        font-weight: bold;
                        margin: 0;
                    }
        
                    .subText{
                        margin-top: 10px;
                    }
        
                    .colSubText{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
        
                    .textValue{
                        text-align: center;
                    }
        
                    .subTextValue{
                        text-align: center;
                    }
        
                    .textFinalValue{
                        text-align: center;
                        font-size: 14px;
                        color: #EE880C;
                        font-weight: bold;
                        margin: 0;
                    }
        
                    .colIcon{
                        margin-top: 10px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
        
                        .icon{
                            width: 30px;
                            height: 30px;
                            color: #EE880C;
                        }
                    }
                }
    
                .rowSubItem{
                    margin-top: 20px;
                    .info{
                        text-align: center;
                    }
        
                    .infoValue{
                        margin-top: 10px;
                    }
                }
            }
        }
    }
}