#DatePagination{
    margin-top: 20px;
    margin-bottom: 40px;

    .container{
        display: flex;
        justify-content: center;
        
        .rowButton{
            width: 100%;
            display: flex;
            justify-content: space-between;
            gap: 10px;

            .button{
                width: 100px;
                background-color: #390040;
                border-color: #390040;
                font-weight: bold;
            }
            
            .buttonPress{
                width: 200px;
                background-color: #EE880C;
                border-color: #EE880C;
                font-weight: bold;
            }
        }
    }
}

@media (max-width:991.98px){
    #DatePagination{
        margin-top: 20px;
        margin-bottom: 20px;
    
        .container{
            display: flex;
            justify-content: center;
            
            .rowButton{
                width: 100%;
                display: flex;
                justify-content: space-between;
                gap: 5px;
    
                .button{
                    width: 100px;
                    background-color: #390040;
                    border-color: #390040;
                    font-weight: bold;
                }
                
                .buttonPress{
                    width: 200px;
                    background-color: #EE880C;
                    border-color: #EE880C;
                    font-weight: bold;
                }
            }
        }
    }
}