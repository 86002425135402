#BannersBlogPage{
    margin-top: 50px;
    margin-bottom: 40px;

    .container{

        .titlePrincipal {
            font-size: 25px;
            font-weight: bold;
            margin-bottom: 1rem;
        }

        .colButtonBanner{
            display: flex;
            justify-content: end;

            .buttonBanner{
                width: 200px;
                height: fit-content;
                font-weight: bold;
                background-color: #390040;
                border-color: #390040;
            }
        }

        .rowForm{
            margin-bottom: 50px;

            .form{
    
                .formTitle{
                    font-size: 18px;
                    font-weight: bold;
                    margin-top: 10px;
                    color: #390040;
                }
    
                .formControl{
                    border-color: #EE880C;
    
                    &:focus {
                        box-shadow: 0 0 5px 2px #EE880C;
                        border-color: #EE880C;
                    }
    
                    option:checked {
                        background-color: #EE880C;
                        color: #fff;
                    }
                }
    
                .colImage{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    
                    .image{
                        margin-top: 15px;
                        width: 200px;
                        height: 150px;
                    }
                }
    
                .colButton{
                    display: flex;
                    justify-content: center;
                    margin-top: 40px;
    
                    .button{
                        width: 200px;
                        height: fit-content;
                        font-weight: bold;
                        background-color: #390040;
                        border-color: #390040;
                    }
                }
    
            }
        }

        .rowDescription{
            display: flex;
            justify-content: center;
            padding-right: 15px;
            margin-top: 10px;
            margin-bottom: 10px;
            
            .textTitle{
                padding-left: 25px;
                font-weight: bold;
            }
    
            .text{
                text-align: center;
                font-weight: bold;
            }
        }
        
        .listGroup{

            .colImage{
                display: flex;
                justify-content: center;

                .image{
                    width: 100%;
                    height: 120px;
                }
            }

            .title{
                font-size: 18px;
                margin: 10px 10px 0px 10px;
            }

            .ordem{
                text-align: center;
                font-size: 16px;
                margin: 10px 10px 0px 10px;
            }

            .rowIcon{
                padding: 10px 10px 10px 10px;
                display: flex;
                justify-content: space-around;
    
                .iconEdit{
                    color: #390040;
                    width: 20px;
                    height: 20px;
                }
    
                .iconDelete{
                    color: #390040;
                    width: 20px;
                    height: 20px;
                }
            }    
        }
    }
}

.modal{
    .title{
        color: #390040;
        font-weight: bold;
    }

    .form{

        .formTitle{
            font-size: 18px;
            font-weight: bold;
            margin-top: 10px;
            margin-bottom: 10px;
        }

        .formLabel {
            border-color: #EE880C;
            &:focus {
                box-shadow: 0 0 5px 2px #EE880C;
                border-color: #EE880C;
            }
        }
        
        .formControl{
            color: #EE880C;
        }

        .divImage{
            display: flex;
            justify-content: center;
            margin-top: 15px;

            .image{
                width: 50%;
                height: auto;
            }
        }
    }
    
    .buttonSave{
        background-color: #390040;
        border-color: #390040;
    }

    .buttonCancel{
        background-color: #EE880C;
        border-color: #EE880C;
    }
}

@media (max-width:991.98px){
    #BannersBlogPage{
        .container{
    
            .colButtonBanner{
                display: block;
                margin-bottom: 10px;
    
                .buttonBanner{
                    width: 160px;
                }
            }

            .rowDescription{
                display: none;
            }  
            
            .listGroup{
                margin-left: 15px;
                .title{
                   text-align: center;
                }
    
                .ordem{
                    text-align: center;
                }
            }
        }
    }
}