#ChangePasswordPage{
    min-height: 60vh;
    padding: 50px 0px 50px 0px;
    
    .rowTitle {
        text-align: center;
        font-size: 25px;
        font-weight: bold;
        color: #390040;
    }

    .rowForm {
        display: flex;
        justify-content: center;
        
        .form{
            width: 60%;

        }

        .formLabel{
            color: #390040;
            font-weight: bold;
            padding-top: 20px;
        }

        .formControl {
            height: 60px;
            border-color: #EE880C;
            border-radius: 15px 0px 0px 15px;

            &:focus {
                box-shadow: 0 0 5px 2px #EE880C;
                border-color: #EE880C;
            }

            &:disabled {
                background-color: #ee880c04;
            }
        }

        .showPasswordButton{
            height: 60px;
            background-color: transparent;
            border-color: #EE880C;
            border-radius: 0px 15px 15px 0px;
            color: #EE880C;
        }

        .rowRecaptcha{
            justify-content: center;
            margin-top: 15px;

            .recaptcha{
                width: auto;
            }
        }

        .rowButton{

            .colCancel{
                display: flex;
                justify-content: end;
                .buttonCancel {
                    width: 50%;
                    height: 50px;
                    border-radius: 10px;
                    border-color: #EE880C;
                    background-color: #EE880C;
                    margin-top: 20px;
                    font-weight: bold;
                }
            }

            .button {
                width: 50%;
                height: 50px;
                border-radius: 10px;
                border-color: #390040;
                background-color: #390040;
                margin-top: 20px;
                font-weight: bold;
            }
        }
    }
}

@media (max-width:991.98px){
    #ChangePasswordPage{
        padding: 20px 0px 20px 0px;
            
        .rowForm {
            .form{
                width: 80%;
            }
    
            .rowButton{
                .colCancel{
                    display: flex;
                    justify-content: center;
                }
                
                .colButton{
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }
}