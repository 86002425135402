#BlogCategoryPage {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
  
    .title {
        font-size: 25px;
        font-weight: bold;
        margin-bottom: 1rem;
    }

    .form{
        .formLabel {
            border-color: #EE880C;
            &:focus {
                box-shadow: 0 0 5px 2px #EE880C;
                border-color: #EE880C;
            }
        }
        
        .formControl{
            color: #EE880C;
        }
    }

    .button{
        width: 100px;
        height: 60px;
        background-color: #390040;
        border-color: #390040;
        font-weight: bold;
    }

    .listGroup{
        margin: 30px 10px 40px 10px;

        .text{
            font-size: 16px;
            margin: 10px 10px 0px 10px;
        }

        .rowIcon{
            padding: 10px 10px 10px 10px;
            display: flex;
            justify-content: space-around;

            .iconEdit{
                color: #390040;
                width: 20px;
                height: 20px;
            }

            .iconDelete{
                color: #390040;
                width: 20px;
                height: 20px;
            }
        }
    }

}
.modal{
    .title{
        color: #390040;
        font-weight: bold;
    }

    .form{
        .formLabel {
            border-color: #EE880C;
            &:focus {
                box-shadow: 0 0 5px 2px #EE880C;
                border-color: #EE880C;
            }
        }
        
        .formControl{
            color: #EE880C;
        }
    }

    .buttonSave{
        background-color: #390040;
        border-color: #390040;
    }

    .buttonCancel{
        background-color: #EE880C;
        border-color: #EE880C;
    }
}

@media (max-width:991.98px){
    #BlogCategoryPage {

        .listGroup{
            padding-right: 20px;

            .text{
                font-size: 18px;
                text-align: center;
            }
        }
    }
}


@media (max-width:575px){
    #BlogCategoryPage {

        .button{
            margin-top: 25px;
            height: 40px;
        }
    }
}