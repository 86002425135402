#SectionBussinessHour {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 40px;

    .title {
        font-size: 25px;
        font-weight: bold;
        margin-bottom: 1rem;
    }

    .colButtonEdit{
        display: flex;
        justify-content: end;

        .buttonEdit{
            width: 200px;
            height: 40px;
            font-weight: bold;
            background-color: #390040;
            border-color: #390040;
        }

        .buttonCancel{
            width: 200px;
            height: 40px;
            font-weight: bold;
            background-color: #EE880C;
            border-color: #EE880C;
        }
    }

    .colForm {
        padding: 30px;
    }

    .rowCard {
        width: auto;
        min-height: 360px;
        border-radius: 19px;
        border-width: 1px;
        border-style: solid; 
        border-color: #390040;
    }
    
    .noBorder {
        border: none;
    }
    
    .colDay {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: #390040;
        border-radius: 15px;
        color: white;
        
        .dayLabel {
            font-size: 25px;
            font-weight: bold;
            text-align: center;
            margin-top: 10px;
            color: white;
        }
    }

    .rowForm {

        .colFormEnd{
            margin-bottom: 20px;
        }

        .formTitle {
            font-size: 18px;
            font-weight: bold;
            margin-top: 10px;
            color: #390040;
        }

        .formSubTitle {
            font-size: 16px;
            font-weight: bold;
            margin-top: 10px;
            color: #390040;
        }

        .formControl {
            border-color: #EE880C;

            &:focus {
                box-shadow: 0 0 5px 2px #EE880C;
                border-color: #EE880C;
            }

            &:checked {
                background-color: #EE880C;
                color: #fff;
            }
        }

        .colCheck {
            margin-top: 15px;
        }
    }
    
    .rowButton{
        margin-top: 30px;
        
        .colButtonSend{
            
            .buttonSend{
                width: 200px;
                height: 40px;
                font-weight: bold;
                background-color: #390040;
                border-color: #390040;
            }
            
        }
        
        .colButtonCancel{
            display: flex;
            justify-content: end;

            .buttonCancel{
                width: 200px;
                height: 40px;
                font-weight: bold;
                background-color: #EE880C;
                border-color: #EE880C;
                margin-right: 15px;
            }
        }

    }
}

@media (max-width:991.98px){
    #SectionBussinessHour {
        .title {
            text-align: center;
        }
        .colButtonEdit{
            justify-content: center;
        }
        .rowButton{
            .colButtonSend{
                .buttonSend{
                    width: 150px;
                }
            }
            .colButtonCancel{
    
                .buttonCancel{
                    width: 150px;
                }
            }
        }
    }
}